<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="电子支付金额" />
    <div>
      <van-tabs v-model="activeName" color='#428dff'>
        <van-tab title="日报" name="day">
          <div v-for="(v,i) in dataList" :key="i" style="padding:16px;background-color:#fff;margin-top:10px" @click="dayFn(v.date)">
            <div>{{v.date}}</div>
            <van-row style="text-align:center" type='flex' justify='space-around'>
              <van-col span="8">
                <div class="title">安装电子支付</div>
                <div class="num">{{v.azdzzf}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szazdzzf>0"><img src="../assets/icon/shangsheng.png">{{v.szazdzzf}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szazdzzf}}%</div>
              </van-col>
              <van-col span="8">
                <div class="title">安装工单量</div>
                <div class="num">{{v.azgdl}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szazgdl>0"><img src="../assets/icon/shangsheng.png">{{v.szazgdl}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szazgdl}}%</div>
              </van-col>
              <van-col span="8">
                <div class="title">开工师傅组</div>
                <div class="num">{{v.kgsfz}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szkgsfz>0"><img src="../assets/icon/shangsheng.png">{{v.szkgsfz}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szkgsfz}}%</div>
              </van-col>
              <van-col span="8">
                <div class="title">维修电子支付</div>
                <div class="num">{{v.wxdzzf}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szwxdzzf>0"><img src="../assets/icon/shangsheng.png">{{v.szwxdzzf}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szwxdzzf}}%</div>
              </van-col>
              <van-col span="8">
                <div class="title">维修工单量</div>
                <div class="num">{{v.wxgdl}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szwxgdl>0"><img src="../assets/icon/shangsheng.png">{{v.szwxgdl}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szwxgdl}}%</div>
              </van-col>
              <van-col span="8">
                <div class="title">开工师傅</div>
                <div class="num">{{v.kgsf}}</div>
                <div class="title">较上周对比</div>
                <div class="num" v-if="v.szkgsf>0"><img src="../assets/icon/shangsheng.png">{{v.szkgsf}}%</div>
                <div class="num2" v-else><img src="../assets/icon/down.png">{{-v.szkgsf}}%</div>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="月报" name="month">
          <div @click="monthFn(v.dmonth)" v-for="(v,i) in listMonth" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
            <div>{{v.dmonth}}</div>
            <van-row style="text-align:center">
              <van-col span="12">
                <div class="title">安装电子支付</div>
                <div class="num">{{v.insamount}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.linsratea<0" class="num2"><img src='../assets/icon/down.png'>{{-v.linsratea}}%</div>
                <div v-else class="num"><img src='../assets/icon/shangsheng.png'>{{v.linsratea}}%</div>
              </van-col>
              <van-col span="12">
                <div class="title">安装工单量</div>
                <div class="num">{{v.insorder}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.linsrateo<0" class="num2"><img src='../assets/icon/down.png'>{{-v.linsrateo}}%</div>
                <div v-else class="num"><img src='../assets/icon/shangsheng.png'>{{v.linsrateo}}%</div>
              </van-col>
              <van-col span="12">
                <div class="title">维修电子支付</div>
                <div class="num">{{v.repamount}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.lrepratea<0" class="num2"><img src='../assets/icon/down.png'>{{-v.lrepratea}}%</div>
                <div v-else class="num"><img src='../assets/icon/shangsheng.png'>{{v.lrepratea}}%</div>
              </van-col>
              <van-col span="12">
                <div class="title">维修工单量</div>
                <div class="num">{{v.reporder}}</div>
                <div class="title">较上月对比</div>
                <div v-if="v.lreprateo<0" class="num2"><img src='../assets/icon/down.png'>{{-v.lreprateo}}%</div>
                <div v-else class="num"><img src='../assets/icon/shangsheng.png'>{{v.lreprateo}}%</div>
              </van-col>
            </van-row>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      activeName: "day",
      dataList: [],
      listMonth: [],
      obj: {
        start: "",
        end: "",
      },
    };
  },
  created() {
    this.obj = {
      start: this.$route.query.start,
      end: this.$route.query.end,
    };
    this.getList();
    this.getListMonth();
  },
  methods: {
    getmonthday(year, month) {
      let days = new Date(year, Number(month), 0).getDate();
      return days;
    },
    //月结跳转
    monthFn(value) {
      this.$router.push({
        path: "/analysis/allNetWorkMonth",
        query: {
          date: value,
        },
      });
    },
    //日报跳转
    dayFn(value) {
      this.$router.push({
        path: "/analysis/allNetWork",
        query: {
          date: value,
        },
      });
    },
    getMyDate(value) {
      const arr = [];
      for (let i = 0; i < value; i++) {
        const time = new Date(
          new Date().setDate(new Date().getDate() + i - value)
        );
        const year = time.getFullYear();
        const month = `0${time.getMonth() + 1}`.slice(-2);
        const strDate = `0${time.getDate()}`.slice(-2);
        arr.push(`${year}-${month}-${strDate}`);
      }
      return arr;
    },
    //获取数据---月报
    getListMonth() {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(), // 获取当前年份
        month: nowDate.getMonth() + 1, //获取当前月份
      };
      let start = date.year + "-01";
      let end =
        date.year + "-" + (date.month > 9 ? date.month : "0" + date.month);

      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startYearMonth: start,
        endYearMonth: end,
        website: "",
        sumType: 0,
      };

      this.postRequest("/wechat-analysis/fees-month", params).then((res) => {
        res.data.forEach((v) => {
          v.insamount = this.$numToFixed(v.insamount);
          v.repamount = this.$numToFixed(v.repamount);
        });
        this.listMonth = res.data.reverse();
      });
    },
    //获取数据---日报
    getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startDate: this.obj.start,
        endDate: this.obj.end,
      };
      this.postRequest("/wechat-analysis/fees-day-wo-website", params).then(
        (res) => {
          // let section = this.$route.query.date;

          // let list = [];
          // for (let i = 0; i < section.length; i++) {
          //   let obj = [];
          //   for (let j = 0; j < res.data.length; j++) {
          //     if (section[i] === res.data[j].ddate) {
          //       obj.push(res.data[j]);
          //     }
          //   }
          //   list.push(obj);
          // }
          // this.dataList = list;

          let time = res.data.map((v) => {
            return v.ddate;
          });
          time = [...new Set(time)];
          console.log(time);
          let list = [];
          for (let i = 0; i < time.length; i++) {
            list[i] = {
              date: time[i],
              azdzzf: 0,
              szazdzzf: 0,
              azgdl: 0,
              szazgdl: 0,
              kgsfz: 0,
              szkgsfz: 0,
              wxdzzf: 0,
              szwxdzzf: 0,
              wxgdl: 0,
              szwxgdl: 0,
              kgsf: 0,
              szkgsf: 0,
            };
            for (let j = 0; j < res.data.length; j++) {
              let zzl =
                ((res.data[j].workers - res.data[j].wworkers) /
                  res.data[j].wworkers) *
                100;
              zzl = zzl.toFixed(2);
              if (
                time[i] === res.data[j].ddate &&
                res.data[j].btype === "材料"
              ) {
                // list[i].azdzzf = res.data[j].amount;
                list[i].azdzzf = this.$numToFixed(res.data[j].amount);
                list[i].szazdzzf = res.data[j].wmr;
                list[i].azgdl = res.data[j].ctorder;
                list[i].szazgdl = res.data[j].wcr;
                list[i].kgsfz = res.data[j].workers;
                list[i].szkgsfz = zzl;
              }
              if (
                time[i] === res.data[j].ddate &&
                res.data[j].btype === "维修"
              ) {
                // list[i].wxdzzf = res.data[j].amount;
                list[i].wxdzzf = this.$numToFixed(res.data[j].amount);
                list[i].szwxdzzf = res.data[j].wmr;
                list[i].wxgdl = res.data[j].ctorder;
                list[i].szwxgdl = res.data[j].wcr;
                list[i].kgsf = res.data[j].workers;
                list[i].szkgsf = zzl;
              }
            }
          }
          // console.log(list);
          this.dataList = list.reverse();
        }
      );
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 12px;
  color: #afafaf;
  margin-bottom: 5px;
  padding-top: 16px;
}
.num {
  font-size: 16px;
  color: #1296db;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
.num2 {
  font-size: 16px;
  color: #d81e06;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
</style>